import {Component} from '@angular/core';

@Component({
  selector: 'agl-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {

  constructor() {
  }

}
