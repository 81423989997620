<div class="container" fxFlex="1 1" fxLayoutAlign="start center">
  <ng-container *ngIf="(type === 'home') || (type === null)">
    <h1 class="__capt" fxLayout="column">
      <mark class="__capt-1">Agile Bear</mark>
      <mark class="__capt-2">Certified Scrum Training</mark>
      <mark class="__capt-3">& Consultancy</mark>
    </h1>
  </ng-container>

  <ng-container *ngIf="type === 'services'">
    <h1 class="__capt" fxLayout="column" *ngIf="h1 == ''">
      <mark class="__capt-2">Our Services</mark>
      <mark class="__capt-3">Training Portfolio</mark>
    </h1>

    <h1 class="__capt" fxLayout="column" *ngIf="h1 != ''">
      <mark *ngIf="h3 != ''" class="__capt-1">{{h3}}</mark>
      <mark class="__capt-2">{{h1}}</mark>
      <mark class="__capt-3">{{h2}}</mark>
    </h1>

    <div class="__logo">
      <agl-services-logos class="--s100" [isGray]="isGray" [usedLogo]="[usedLogo]" [popoverTrigger]="false"></agl-services-logos>
    </div>
  </ng-container>

  <ng-container *ngIf="type === 'training'">
    <h1 class="__capt" fxLayout="column">
      <mark class="__capt-2">Scrum Training</mark>
      <mark class="__capt-3" *ngIf="h3 == ''">Our Public Courses</mark>
      <mark class="__capt-3" *ngIf="h3 != ''">{{h3}}</mark>
<!--      <agl-event-date-line class="&#45;&#45;second gm-mt-2" [days]="[2,3,4]" [month]="'March'" [year]="2021"></agl-event-date-line>-->
    </h1>
  </ng-container>

  <ng-container *ngIf="type === 'contact'">
    <h1 class="__capt" fxLayout="column">
      <mark class="__capt-1">Get in Touch</mark>
      <mark class="__capt-3">We're here to help</mark>
    </h1>
  </ng-container>

  <ng-container *ngIf="type === 'about'">
    <h1 class="__capt" fxLayout="column">
      <mark class="__capt-1">What we're all about</mark>
      <mark class="__capt-3">& who we are</mark>
    </h1>
  </ng-container>
</div>
