import {Component, HostListener, Inject, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';

/*
            Certified ScrumMaster Training
            Certified Product Owner Training
            The Cultural Change
            Advanced Certified ScrumMaster Training
            Enterprise Organisational Change For Coaches
            Certified Scrum Developer
            Testing On Agile Projects
            User Story Workshops
            Agile Coaching Sessions
            Lean Workshops
            Verified Scrum Overview
            X- Scrum Dragon ->
*/

const SERVICES_LINKS = [
  {
    name: 'Certified ScrumMaster Training',
    link: '/services/certified-scrum-master',
    icon: 'csm'
  },
  {
    name: 'Certified Product Owner Training',
    link: '/services/certified-product-owner',
    icon: 'cspo'
  },
  {
    name: 'The Cultural Change',
    link: '/services/the-cultural-change',
    icon: 'tcc'
  },
  {
    name: 'Advanced Certified ScrumMaster Training',
    link: '/services/advanced-certified-scrum-master',
    icon: 'a-csm'
  },
  {
    name: 'Advanced Certified Product Owner Training',
    link: '/services/advanced-certified-product-owner',
    icon: 'a-csm'
  },  
  {
    name: 'Enterprise Organisational Change for Coaches',
    link: 'services/enterprise-organisational-changes-for-coaches',
    icon: 'so'
  },
  {
    name: 'Certified Scrum Developer',
    link: 'services/certified-scrum-developer',
    icon: 'csd'
  },
  {
    name: 'Testing on Agile Projects',
    link: 'services/testing-on-agile-projects',
    icon: 'toap'
  },
  {
    name: 'User Story Workshops',
    link: '/services/user-story-workshops',
    icon: 'usw'
  },
  {
    name: 'Agile Coaching Sessions',
    link: '/services/agile-coaching-sessions',
    icon: 'acs'
  },
  {
    name: 'Lean Workshops',
    link: '/services/lean-workshops',
    icon: 'lw'
  },
  {
    name: 'Scrum Overview',
    link: '/services/scrum-overview',
    icon: 'so'
  },
  // {
  //   name: 'Scrum Dragon',
  //   link: '/services/program',
  //   icon: 'sd'
  // },
];

const TRAINING_LINKS = [
  // {
  //   name: 'Certified ScrumMaster Training',
  //   link: '/events:1',
  //   date: ' March 2021',
  //   icon: 'csm'
  // },
  {
    name: 'Certified Product Owner Training',
    link: '/events:1',
    date: '8 - 10 March, 2022',
    // icon: 'cspo'
  },
  {
    name: 'Advanced Certified ScrumMaster Training',
    link: '/events:2',
    date: '5 - 7 April, 2022',
    // icon: 'acs'
  },
];

@Component({
  selector: 'agl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  html: HTMLElement = this.document.getElementsByTagName('html')[0];
  body: HTMLElement = this.document.getElementsByTagName('body')[0];
  isMenuOpen: boolean;

  @Input() type: string;

  dwServiceList = SERVICES_LINKS;
  dwTrainingList = TRAINING_LINKS;

  get isTrainingPage(): boolean {
    return (this.router.url.includes('scrum-training'));
  };

  get isServicesPage(): boolean {
    return (this.router.url.includes('services'));
  };

  get isYellow(): boolean {
    return (this.type === 'b');
  }

  get isOrange(): boolean {
    return (this.type === 'c');
  }

  @HostListener('window:scroll', []) onWindowScroll() {
    const scrollPos = document.documentElement.scrollTop;

    if (scrollPos >= 5) {
      this.html.classList.add('--scroll-header-opacity');
    } else {
      this.html.classList.remove('--scroll-header-opacity');
    }
  }

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
  }

  triggerMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;

    this.isMenuOpen ? this.body.classList.add('gm-scroll-hide') : this.body.classList.remove('gm-scroll-hide');
  }
}
