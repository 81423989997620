<div class="__cont" fxLayoutAlign.gt-sm="center center" fxLayout.gt-sm="row nowrap" gdGap.lt-sm="2rem">
  <a class="__item" href="mailto:enquiries@agilebear.com?subject=Get in touch with Agilebear">Enquiries…</a> <!-- temp ajs -->

  <!-- <a class="__item" href *ngIf="!type">Upcoming Courses</a> ajs -->
  <a class="__item" routerLink="/scrum-training" *ngIf="!hideBook">Upcoming Courses</a>

  <agl-button class="--nowrap"
              [ngClass]="'--' + color" *ngIf="!hideBook">Book Your Place
  </agl-button>
</div>
