<article class="container" gdGap="1rem" [ngClass]="'--' + color">
  <header>
    <agl-heading>Program</agl-heading>
  </header>

  <div gdColumns.gt-sm="1fr 1fr" gdGap="1.875rem">
    <div>
      <agl-heading class="--large" first="PT." second="01"></agl-heading>

      <ol class="list-num">
        <li>Expectations of the Product Owner</li>
        <li>Scrum overview</li>
        <li>History of Agile and Scrum</li>
        <li>Benefits of Scrum</li>
        <li>The Scrum framework</li>
        <li>Scrum Roles</li>
        <li>The Product Vision:
          <ol class="list-num --second">
            <li data-counter="a"> Benefits of the Product Vision</li>
            <li data-counter="b"> The Vision in Action</li>
            <li data-counter="c"> Desirable Qualities</li>
            <li data-counter="d"> Techniques for Creating a Powerful Vision</li>
          </ol>
        </li>

        <li>User stories on the product backlog:
          <ol class="list-num --second">
            <li data-counter="a"> Augmenting the user stories</li>
            <li data-counter="b"> User roles/Personas</li>
            <li data-counter="c"> INVEST in your backlog</li>
          </ol>
        </li>

        <li>Stocking the Product Backlog:
          <ol class="list-num --second">
            <li data-counter="a">Failure of upfront thinking</li>
            <li data-counter="b">Emergent requirements</li>
            <li data-counter="c">Release Planning</li>
            <li data-counter="d">Velocity</li>
          </ol>
        </li>

        <li>What is Done?</li>
        <li>Agile Estimating in brief</li>
      </ol>
    </div>

    <div>
      <agl-heading class="--large" first="PT." second="01"></agl-heading>

      <ol class="list-num --set-increment-11">
        <li>Prioritizing the product backlog</li>
        <li>Kano analysis</li>
        <li>Theme Screening and Theme Scoring</li>
        <li>Innovation Games</li>
        <li>Relative Weighting</li>
        <li>Priority Poker</li>
        <li>Understanding Sprint Planning</li>
        <li>Understanding the Daily Scrum</li>
        <li>Sprint Review</li>
        <li>Managing Releases:
          <ol class="list-num --second">
            <li data-counter="a"> Release Burndown</li>
            <li data-counter="b"> Rolling Release Plans</li>
          </ol>
        </li>
        <li>10 Tips for Product Owners</li>
        <li>Summary and Close</li>
      </ol>
    </div>
  </div>
</article>
