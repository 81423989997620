import {Component} from '@angular/core';

@Component({
  selector: 'agl-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {

  constructor() {
  }
}
