<div class="__wrap container" gdColumns.gt-sm=" 1fr 1fr" [attr.background]="image">
  <div class="__left" fxLayout="column" >
    <ng-container *ngIf="type === 'a' || type === null ">
      <div class="__text">
        <h3 class="__title">{{title}}</h3>
        <div>
          <ng-content></ng-content>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="type === 'b'">
      <mark class="__text-top">
        <ng-content select="[slot=header]"></ng-content>
      </mark>

      <div class="__text-r">
        <ng-content select="[slot=list]"></ng-content>
      </div>
    </ng-container>
  </div>

  <div></div>
</div>
