<div class="__group" [class.--is-error]="isError" style="display: block;">
  <mark class="__msg" *ngIf="isError">
    <!--    <agl-validation-messages-->
    <!--      [errorsMsg]="errorsMsg"-->
    <!--    >-->
    <!--    </agl-validation-messages>-->
    <stan></stan>
  </mark>

  <textarea class="__field"
            [ngClass]="{'--error-pdd': isError }"
            [placeholder]="placeholder"
            [disabled]="disabled"
            (ngModelChange)="onChange($event)"
            [(ngModel)]="value"></textarea>

  <mark class="__hidden-value"
        fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="0.6rem"
        *ngIf="isError"
  >
    <span *ngIf="!value">{{ placeholder }}</span>
    <span *ngIf="value">{{ inputRef.value }}</span>
    <agl-validation-messages [errorsMsg]="errorsMsg"></agl-validation-messages>
  </mark>
</div>
