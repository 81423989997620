import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'agl-two-agenda-blocks',
  templateUrl: './two-agenda-blocks.component.html',
  styleUrls: ['./two-agenda-blocks.component.scss']
})
export class TwoAgendaBlocksComponent implements OnInit {

  @Input() color: string = "orange";
  @Input() title: string = "Agenda";
  @Input() subheader: string = "The workshop agenda is fluid due to the nature of the programme. A typical event would look something like this.";

  constructor() { }

  ngOnInit(): void {
  }

}
