import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'agl-three-col-program',
  templateUrl: './three-col-program.component.html',
  styleUrls: ['./three-col-program.component.scss']
})
export class ThreeColProgramComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
