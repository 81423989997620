<!--<label class="__label">{{ label }}</label>-->

<div class="__group" [class.--is-error]="isError">
  <mark class="__msg" *ngIf="isError">
    <!--    <agl-validation-messages-->
    <!--      [errorsMsg]="errorsMsg"-->
    <!--    >-->
    <!--    </agl-validation-messages>-->
    <stan></stan>
  </mark>

  <input
    class="__input"
    [ngClass]="{'--error-pdd': isError }"
    [type]="type"
    [placeholder]="!importantPlace? placeholder: ''"
    [disabled]="disabled"
    (ngModelChange)="onChange($event)"
    [(ngModel)]="value"
    [attr.inputmode]="inputMode"
    #inputRef
    required
  />

  <div class="__placeholder" *ngIf="!value && importantPlace">{{ placeholder }}</div>

  <mark class="__hidden-value"
        fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="0.6rem"
        *ngIf="isError"
  >
    <span *ngIf="!value">{{ placeholder }}</span>
    <span *ngIf="value">{{ inputRef.value }}</span>
    <agl-validation-messages [errorsMsg]="errorsMsg"></agl-validation-messages>
  </mark>
</div>
