import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'agl-two-column-with-header',
  templateUrl: './two-column-with-header.component.html',
  styleUrls: ['./two-column-with-header.component.scss']
})
export class TwoColumnWithHeaderComponent implements OnInit {

  @Input() h1: string = '';
  @Input() h2: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
