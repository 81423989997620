<article gdGap="1.5rem">
  <figure>
    <img class="__img" [attr.src]="'assets/img/popular/' + image" alt="">
  </figure>

  <div>
    <div gdGap="0.8rem" gdColumns="1fr 3.875rem">
      <h3 class="__capt">{{ caption }}</h3>

      <agl-services-logos class="__logo --s70"
                          [isGray]="false" [usedLogo]="[logo]"
                          [popoverTrigger]="true">
      </agl-services-logos>
    </div>

    <p class="__desk">{{description}}</p>

    <div fxLayoutAlign="end center" fxLayoutGap="0.75rem">
      <agl-link class="--arrow --y-orange" [routerLink]="link">Find out more</agl-link>
      <agl-button *ngIf="bookLink != ''" class="--yellow-orange" [routerLink]="bookLink">Book Your Place</agl-button>
    </div>
  </div>
</article>
