import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'agl-top-panel',
  templateUrl: './top-panel.component.html',
  styleUrls: ['./top-panel.component.scss']
})
export class TopPanelComponent implements OnInit {

  @Input() type: string = 'home';
  @Input() h1: string = '';
  @Input() h2: string = '';
  @Input() h3: string = '';
  @Input() isGray: boolean = true;
  @Input() usedLogo: string = 'a-csm';

  @HostBinding('class.--second') get isTraining() {
    return (this.type === 'training');
  }

  @HostBinding('class.--third') get isServices() {
    return (this.type === 'services');
  }

  @HostBinding('class.--fourth') get isContact() {
    return (this.type === 'contact');
  }

  @HostBinding('class.--fifth') get isAbout() {
    return (this.type === 'about');
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
