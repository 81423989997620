import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'agl-three-pt-blocks',
  templateUrl: './three-pt-blocks.component.html',
  styleUrls: ['./three-pt-blocks.component.scss']
})
export class ThreePtBlocksComponent implements OnInit {

  @Input() color: string = "orange";

  constructor() { }

  ngOnInit(): void {
  }

}
