<header>
  <div class="container" fxLayout="row nowrap" fxLayoutGap.lt-md="1rem">
    <agl-logo style="display: block;"></agl-logo>

    <nav class="__nav" gdInline gdGap="0.5rem" gdAuto="column">
      <a class="__link" routerLink="/" routerLinkActive="--active"
         [routerLinkActiveOptions]="{exact:true}"
         gdAlignColumns="center">Home</a>
      <a class="__link" routerLink="/about-us" routerLinkActive="--active" gdAlignColumns="center">About Us</a>

      <a class="__link" id="dropdownServices" gdAlignColumns="center"
         [class.--active]="isServicesPage"
         ngbDropdown ngbDropdownToggle>
        <span>Services</span>

        <div class="__dropdown" ngbDropdownMenu aria-labelledby="dropdownServices">
          <div gdGap="0.25rem">
            <a class="__dropdown-item" routerLink="/services"
               [routerLinkActive]="'--is-active'"
               ngbDropdownItem fxLayoutGap="0.5rem"
               fxLayoutAlign="start center">
              <span>Overview</span>
            </a>

            <a class="__dropdown-item"
               *ngFor="let item of dwServiceList "
               [routerLink]="item.link"
               [routerLinkActive]="'--is-active'"
               ngbDropdownItem fxLayoutGap="0.5rem" fxLayoutAlign="start baseline">
              <span>{{item.name}}</span>
            </a>
          </div>
        </div>
      </a>

      <a class="__link" routerLink="/scrum-training" routerLinkActive="--active" gdAlignColumns="center">Scrum Training</a>

      <!-- <a class="__link" routerLink="/contact" routerLinkActive="--active" gdAlignColumns="center">Contact Us</a> -->
      <a class="__link" href="mailto:get-in-touch@agilebear.com?subject=Get in touch with Agilebear" gdAlignColumns="center">Contact Us</a>
    </nav>

    <div class="__right" fxLayout="row nowrap" fxLayoutAlign="space-between center">
      <agl-button routerLink="/event/booking-form" [ngClass]="{'--orange': isOrange , '--yellow-orange': isYellow}">Book Now!</agl-button>

      <a class="__link --tell" href="tel:01173179269">0117 317 9269</a>
    </div>

    <agl-button-menu class="__btn-menu"
                     [ngClass]="{ '--is-open --is-open-hide': isMenuOpen }"
                     (click)="triggerMenu()"></agl-button-menu>
  </div>
</header>

<agl-main-menu [class.--is-open]="isMenuOpen" [ngClass]="{ '--is-open': isMenuOpen }"
               [sunMenu1]="dwServiceList"  [sunMenu2]="dwTrainingList"
               (clickLinkEvent)="triggerMenu()">
  <agl-button-menu [ngClass]="{ '--is-open': isMenuOpen }" (click)="triggerMenu()"></agl-button-menu>
</agl-main-menu>
