<div fxLayoutAlign="center center" fxLayoutGap="0.3rem">
  <div fxLayoutGap="0.5rem">
    <span class="__day" *ngFor="let item of days; let c = count; let i = index"
          [class.--not-after]="(i == (c-2))&& (c>2)">
      {{item}}<sup>{{ item | endingNumb :{cutNumb: true} }}</sup>
    </span>
  </div>

  <div fxLayout="row nowrap" fxLayoutGap="0.3rem">
    <mark class="__month">{{ month }}</mark>
    <mark class="__year">{{ year }}</mark>
  </div>
</div>
