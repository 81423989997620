import {Component, Input} from '@angular/core';

@Component({
  selector: 'agl-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {
  @Input() link: string;
  @Input() overrideText: string = "";
  @Input() isExternal: boolean = false;
}
