<div class="container" gdColumns="repeat(auto-fit, minmax(20rem, 1fr))" gdColumns.lt-sm="1fr" gdGap="2rem">
  <article>
    <div fxLayout="column" fxLayoutGap="1.735rem">
      <ng-content></ng-content>
    </div>
  </article>

  <article>
    <img [src]="'assets/img/' + img" alt="">
  </article>
</div>
