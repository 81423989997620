<article class="container" gdGap="1rem" [ngClass]="'--' + color">
  <header>
    <agl-heading>{{title}}</agl-heading>
  </header>

  <p>{{subheader}}</p>

  <div gdColumns.gt-sm="1fr 1fr" gdGap="1.875rem">
    <div>
      <ng-content select="[slot=first]"></ng-content>
    </div>

    <div>
      <ng-content select="[slot=second]"></ng-content>
    </div>
  </div>
</article>
