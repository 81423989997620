<article class="container" gdGap="2.125rem">
  <header>
    <agl-heading>Program</agl-heading>

    <p class="__top-text" style="margin-top: 2.125rem;">Each module includes small group exercises<br>and discussions in addition to the major exercises
      listed.</p>
  </header>

  <div gdColumns="1fr" gdColumns.gt-sm="1fr 1fr 1fr" gdGap="1.875rem" gdGap.gt-lg="3.5rem">
    <article fxLayout="column" fxLayoutGap="2.375rem">
      <agl-heading class="--large" first="DAY" second="01"></agl-heading>

      <ng-content select="[slot=first]"></ng-content>
    </article>

    <article fxLayout="column" fxLayoutGap="2.375rem">
      <agl-heading class="--large" first="DAY" second="02"></agl-heading>

      <ng-content select="[slot=second]"></ng-content>
    </article>

    <article fxLayout="column" fxLayoutGap="2.375rem">
      <agl-heading class="--large" first="DAY" second="03"></agl-heading>

      <ng-content select="[slot=third]"></ng-content>
    </article>

  </div>
</article>
