<div class="container" fxLayoutAlign.gt-sm="space-between center" fxLayoutGap.gt-sm="1rem"
     gdGap.lt-md="2.5rem" gdAlignColumns.lt-md="center center" gdAlignRows.lt-md="center center">
  <div class="__capt">
    Duration:

    <strong>
      <span>{{days}}</span>
      <mark>Days</mark>
    </strong>
  </div>

  <div fxLayoutAlign="center center">
    <agl-services-place class="__places --is-mob" gdGridAlign="start" [color]="color" [type]="orangeColor" [hideBook]="hideBook"></agl-services-place>
  </div>

  <figure class="__logo"  fxLayoutAlign="center center" fxLayout="column">
    <img [attr.src]="['assets/img/' + img]" alt="">
  </figure>
</div>
