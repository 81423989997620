<input
  class="__input"
  type="checkbox"
  [ngClass]="{ '--is-checked': checked, '--is-error': isError}"
  [checked]="checked"
  (change)="onChange($event)"
  (click)="onClick($event)"
/>

<div>
  <mark class="__mark" fxLayout="row inline"></mark>
</div>

<label class="__label">
  <ng-content></ng-content>
</label>
