import {Component, ElementRef, forwardRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ElementBase} from "@ui/components/element-base";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'agl-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent extends ElementBase implements ControlValueAccessor {

  @ViewChild('inputref') inputRef: ElementRef;
  @ViewChild('validationMessages') validationMessages: ElementRef;

  @Input() label: string;
  @Input() placeholder = 'Enter text';
  @Input() submitted: boolean;
  @Input() value: any;
  @Input() type = 'text';
  @Input() inputMode = 'text';
  @Input() autofocus: boolean;
  @Input() errorsMsg: string;
  @Input() importantPlace: boolean;

  disabled: any = false;

  widthValidationMessages;

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
  }

  // ngOnInit(): void {
  //   if (this.autofocus) {
  //     window.setTimeout(() => {
  //       this.inputRef.nativeElement.focus();
  //     }, 100);
  //   }
  // }

  onChange: any = () => {}
  onTouch: any = () => {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Step 4: Define what should happen in this component, if something changes outside
  writeValue(value: string) {
    this.value = value;
  }

  public onFocus(event) {

    setTimeout(() => {
      this.inputRef.nativeElement.selectionStart = 1;
      this.inputRef.nativeElement.selectionEnd = 1;
      this.inputRef.nativeElement.focus();
    });
  }

  public onBlur(event) {
  }
}
