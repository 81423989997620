import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'agl-two-col-with-image',
  templateUrl: './two-col-with-image.component.html',
  styleUrls: ['./two-col-with-image.component.scss']
})
export class TwoColWithImageComponent implements OnInit {

  @Input() img:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
