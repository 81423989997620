import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'agl-services-place',
  templateUrl: './services-place.component.html',
  styleUrls: ['./services-place.component.scss']
})
export class ServicesPlaceComponent {

  @Input() type: boolean;
  @Input() hideBook: boolean;
  @Input() color: string;

  constructor() {
  }

  @HostBinding('class.--orange') get isColorOrange(): boolean {
    return (this.color === 'orange');
  }

  @HostBinding('class.--yellow') get isColorYellow(): boolean {
    return (this.color === 'yellow');
  }

  @HostBinding('class.--green') get isColorGreen(): boolean {
    return (this.color === 'green');
  }

  @HostBinding('class.--red') get isColorRed(): boolean {
    return (this.color === 'red');
  }

  @HostBinding('class.--blue') get isColorBlue(): boolean {
    return (this.color === 'blue');
  }
}
