import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';

const SERVICES_LOGOS = [
  {
    name: 'a-csm',
    img: 'a_csm.png',
    imgS: 'a_csm.png',
    // imgS: 'a_csm_grey.png',
    caption: 'A CSM',
    text: 'Advanced Certified ScrumMaster',
    colorClass: '--yellow'
  },
  {
    name: 'acs',
    img: 'acs.png',
    imgS: 'acs.png',
    caption: 'ACS',
    text: 'Agile Coaching Sessions',
    colorClass: '--green'
  },
  {
    name: 'csd',
    img: 'csd.png',
    imgS: 'csd.png',
    caption: 'CSD',
    text: 'Certified Scrum Developer',
    colorClass: '--red'
  },
  {
    name: 'csm',
    img: 'csm.png',
    imgS: 'csm.png',
    caption: 'CSM',
    text: 'Certified ScrumMaster',
    colorClass: '--yellow'
  },
  {
    name: 'csp',
    img: 'csp.png',
    imgS: 'csp.png',
    caption: 'CSP',
    text: 'Certified Scrum Practitioner',
    colorClass: '--blue'
  },
  {
    name: 'cspo',
    img: 'cspo.png',
    imgS: 'cspo.png',
    caption: 'CSPO',
    text: 'Certified Scrum Product Owner',
    colorClass: '--orange'
  },
  {
    name: 'a-cspo',
    img: 'a-cspo.png',
    imgS: 'a-cspo.png',
    caption: 'A-CSPO',
    text: 'Advanced Certified Scrum Product Owner',
    colorClass: '--orange'
  },
  {
    name: 'cst',
    img: 'cst.png',
    imgS: 'cst.png',
    caption: 'CST',
    text: 'Certified Scrum Trainer',
    colorClass: '--gray'
  },
  {
    name: 'eocfc',
    img: 'eocfc.png',
    imgS: 'eocfc.png',
    caption: 'EOCFC',
    text: 'Enterprise Organisational Change for Coaches',
    colorClass: '--yellow'
  },
  {
    name: 'lw',
    img: 'lw.png',
    imgS: 'lw.png',
    caption: 'LW',
    text: 'Lean Workshops',
    colorClass: '--yellow'
  },
  {
    name: 'rep',
    img: 'rep.png',
    imgS: 'rep.png',
    caption: 'REP',
    text: 'Some test',
    colorClass: ''
  },
  {
    name: 'sd',
    img: 'sd.png',
    imgS: 'sd.png',
    caption: 'SD',
    text: 'Scrum Dragon',
    colorClass: '--green'
  },
  {
    name: 'so',
    img: 'so.png',
    imgS: 'so_.png',
    caption: 'SO',
    text: 'Scrum Overview',
    colorClass: '--blue'
  },
  {
    name: 'vso',
    img: 'agilebear_vso.png',
    imgS: 'agilebear_vso.png',
    caption: 'VSO',
    text: 'Verified Scrum Overview',
    colorClass: '--orange'
  },
  {
    name: 'tcc',
    img: 'tcc.png',
    imgS: 'tcc.png',
    caption: 'TCC',
    text: 'The Cultural Change',
    colorClass: '--blue'
  },
  {
    name: 'toap',
    img: 'toap.png',
    imgS: 'toap.png',
    caption: 'TOAP',
    text: 'Testing On Agile Projects',
    colorClass: '--green'
  },
  {
    name: 'usw',
    img: 'usw.png',
    imgS: 'usw.png',
    caption: 'USW',
    text: 'User Story Workshops',
    colorClass: '--red'
  },
  {
    name: 'csp-sm',
    img: 'csp-sm.png',
    imgS: 'csp-sm.png',
    caption: 'CSP-SM',
    text: 'Certified Scrum Professional - ScrumMaster',
    colorClass: '--blue'
  },

  /*  {
      name: '',
      img: '',
      imgS: '',
      caption: '',
      text: 'Some test'
    },*/
];

@Component({
  selector: 'agl-services-logos',
  templateUrl: './services-logos.component.html',
  styleUrls: ['./services-logos.component.scss']
})
export class ServicesLogosComponent implements OnInit {

  @Input() usedLogo: any = [];
  @Input() isGray: boolean;
  @Input() popoverTrigger: boolean = false;

  list: any = SERVICES_LOGOS;

  constructor() {

  }

  state: number;

  // @HostListener('mouseover') isHover(): boolean {
  //   return true;
  // }

  ngOnInit(): void {
    this.list = SERVICES_LOGOS.filter((item) => {
      return this.usedLogo.filter((item2) => {
        return item2 === item.name;
      }).length !== 0;
    });
  }
}
