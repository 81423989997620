<article class="container" gdGap="1rem" [ngClass]="'--' + color">
  <header>
    <agl-heading>Program</agl-heading>
  </header>

  <div gdColumns.gt-sm="1fr 1fr 1fr" gdGap="1.875rem">
    <div>
      <agl-heading class="--large" first="PT." second="01"></agl-heading>

      <ng-content select="[slot=first]"></ng-content>
    </div>

    <div>
      <agl-heading class="--large" first="PT." second="02"></agl-heading>

      <ng-content select="[slot=second]"></ng-content>
    </div>

    <div>
      <agl-heading class="--large" first="PT." second="03"></agl-heading>

      <ng-content select="[slot=third]"></ng-content>
    </div>
  </div>
</article>
