import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'agl-who-should-attend',
  templateUrl: './who-should-attend.component.html',
  styleUrls: ['./who-should-attend.component.scss']
})
export class WhoShouldAttendComponent implements OnInit {

  @Input() type: string = 'a';
  @Input() image: string = 'agilebear_02';
  @Input() title: string = 'agilebear_02';

  constructor() {
  }

  @HostBinding('class.--second') get isTypeSecond(): boolean {
    return (this.type === 'b');
  }

  @HostBinding('class.--first-image') get isImageFirst(): boolean {
    return (this.image === 'agilebear_01');
  }

  @HostBinding('class.--second-image') get isImageSecond(): boolean {
    return (this.image === 'agilebear_02');
  }

  @HostBinding('class.--third-image') get isImageThird(): boolean {
    return (this.image === 'agilebear_03');
  }

  @HostBinding('class.--fourth-image') get isImageFourth(): boolean {
    return (this.image === 'agilebear_04');
  }

  ngOnInit(): void {
  }
}
