import { Component } from '@angular/core';

@Component({
  selector: 'agl-button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss']
})
export class ButtonMenuComponent {
  constructor() {}
}
