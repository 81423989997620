import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopBgSectionComponent} from './components/top-bg-section/top-bg-section.component';
import {TopPanelComponent} from './components/top-panel/top-panel.component';
import {MainMenuComponent} from './components/main-menu/main-menu.component';
import {RouterModule} from '@angular/router';
import {FlexModule, GridModule} from '@angular/flex-layout';
import {UiModule} from '../ui/ui.module';
import {ServicesPlaceComponent} from './components/services-place/services-place.component';
import {ServicesLogosComponent} from './components/services-logos/services-logos.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PopularCardComponent} from './components/popular-card/popular-card.component';
import {TeamPhotoComponent} from './components/team-photo/team-photo.component';
import {EventDateLineComponent} from './components/event-date-line/event-date-line.component';
import {EndingNumb} from '@shared/pipes/ending-numb.pipe';
import { WhoShouldAttendComponent } from './components/who-should-attend/who-should-attend.component';
import { ServicesTopStripComponent } from './components/services-top-strip/services-top-strip.component';
import {ContactIntroComponent} from './components/contact-intro/contact-intro.component';
import { TwoColumnWithHeaderComponent } from './components/text/two-column-with-header/two-column-with-header.component';
import { PtBlocksComponent } from './components/program/pt-blocks/pt-blocks.component';
import { AgendaComponent } from './components/program/agenda/agenda.component';
import { ThreeColProgramComponent } from './components/program/three-col-program/three-col-program.component';
import { TwoColWithImageComponent } from './components/text/two-col-with-image/two-col-with-image.component';
import { ThreePtBlocksComponent } from './components/program/three-pt-blocks/three-pt-blocks.component';
import { TwoPtBlocksComponent } from './components/program/two-pt-blocks/two-pt-blocks.component';
import { TwoAgendaBlocksComponent } from './components/program/two-agenda-blocks/two-agenda-blocks.component';
import { OneColProgramComponent } from './components/program/one-col-program/one-col-program.component';
import {nl2br} from "@shared/pipes/nl2br.pipe";

const COMPONENTS = [
  TopBgSectionComponent,
  TopPanelComponent,
  MainMenuComponent,
  ServicesPlaceComponent,
  ServicesLogosComponent,
  PopularCardComponent,
  TeamPhotoComponent,
  EventDateLineComponent,
  WhoShouldAttendComponent,
  ServicesTopStripComponent,
  ContactIntroComponent
];

const PIPES = [EndingNumb, nl2br];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES, TwoColumnWithHeaderComponent, PtBlocksComponent, AgendaComponent, ThreeColProgramComponent, TwoColWithImageComponent, ThreePtBlocksComponent, TwoPtBlocksComponent, TwoAgendaBlocksComponent, OneColProgramComponent],
  exports: [...COMPONENTS, ...PIPES, TwoColumnWithHeaderComponent, PtBlocksComponent, AgendaComponent, ThreeColProgramComponent, TwoColWithImageComponent, ThreePtBlocksComponent, TwoPtBlocksComponent, TwoAgendaBlocksComponent, OneColProgramComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,
    GridModule,
    UiModule,
    NgbModule
  ]
})
export class SharedModule {
}
