<footer class="__footer container" fxLayout="column">
  <ng-container *ngIf="footerType === 'a' || footerType === null">
    <article class="__book --a" gdColumns.gt-xs=" 2fr 1fr" gdGap="1.5rem">
      <agl-services-logos class="--nowrap" [usedLogo]="logos"></agl-services-logos>

      <div fxLayoutGap="2rem" fxLayoutAlign="center center">
        <agl-button class="--upload"></agl-button>
      </div>
    </article>
  </ng-container>

  <ng-container *ngIf="footerType === 'b'">
    <div fxLayoutAlign="center center">
      <div class="__btn-center">
        <agl-button class="--upload" (click)="gotoTop()" style="z-index: 999; position: relative;"></agl-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="footerType == 'c' || footerType == 'c2'">
    <article class="__book" gdColumns="4.875rem 1fr 1fr"
             gdColumns.lt-md="1fr"
             gdAlignColumns.lt-md="center center" gdAlignRows.lt-md="center center"
             gdGap="1.5rem" gdGap.lt-md="1rem">
      <agl-services-logos [usedLogo]="[ orangeColor ?  'cspo' : 'csm']"></agl-services-logos>

      <agl-services-place class="--is-mob" gdGridAlign="start" gdGridAlign.lt-md="center center"
                          [type]="orangeColor"></agl-services-place>

      <div class="__right-upload" fxLayoutGap.lt-sm="2rem" fxLayoutAlign="center center" gdGridAlign.gt-md="center">
        <agl-button-download>DOWNLOAD<BR>TRAINING<BR>PORTFOLIO</agl-button-download>
        <agl-button class="--upload"></agl-button>
      </div>
    </article>
  </ng-container>

  <article class="__content" fxFlex="1 1">
    <ng-container *ngIf="footerType === 'a' || footerType === null">
      <p class="__text-l">
        <span>Something here that explains that Nigel is certified in all the above ScrumAlliance Course.The same may also true of your other trainers - either way - I think it would be a goodidea ifwe explain here exactly what these certifications means. and also itwould be nice if when we rollover each badge we have a little explainer appear that give the full title etc.
        </span>
      </p>
    </ng-container>

    <ng-container *ngIf="footerType === 'c' || footerType === 'c2'">
      <p class="__text-l --mark">
        <mark>*</mark>
        <span>
          During the world crisis, the ScrumAlliance are permitting us to offer you the opportunity to attend live online
        courses. These virtual workshops comply with the ScrumAlliance values, standards and certifications. They
        include at least14 hours of face-to-face time with our world-leading Certified Scrum Trainer Nigel Baker. As
        with our in-person events, Nigel will deliver each exercise-driven workshop with energy, experienceand
        practical content. But better yet, to make the online experience more pleasant for you, wewill run our
        traditionally two-day events over three shorter days. We want to ensure yourexperience is enjoyable,
        sustainable and to support your work-life balance.
        </span>
      </p>
    </ng-container>

    <p class="__text-r clear">
      “Your obvious enthusiasm and knowledge makes the time fly and brings to life the material!”
    </p>
  </article>

  <article class="__down" gdGap="1rem" gdColumns="1fr 2fr 1fr" gdColumns.lt-md="1fr" gdAlignColumns="center">
    <div fxLayout="inline" fxLayoutGap="0.625rem " gdGridAlign.lt-sm="center" gdGridAlign.lt-md="center center">
      <!-- <a class="agi-soc-fb"></a> -->
      <!-- <a class="agi-soc-tw"></a> -->
      <!-- <a class="agi-soc-ln"></a> -->
    </div>

    <div class="__copy" style="color: black;">
      © 2022 AgileBear Limited. All Rights Reserved.
      <br>
      <span>Registered Company Number: 6453580 VAT Number: 924403937</span>
    </div>

    <div class="__links" fxLayoutGap="1rem" fxLayoutAlign="center">
      <!-- <agl-link [link]="'/privacy'">Privacy Policy</agl-link> -->
      <!-- <span>|</span> -->
      <!-- <agl-link [link]="'/terms-of-use'">Terms Of Use</agl-link> -->
    </div>
  </article>
</footer>
